import React, { useState } from "react"
import { graphql } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

import { Header, Content } from "page_components/news"

const News = ({ data, location }) => {
  const title = "Aktualności"
  const [cityParam] = useQueryParam("city", StringParam)

  const [cat, setCat] = useState(cityParam ? cityParam : "")

  const newsData = {
    cat,
    setCat,
  }

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
      rmLangSwitcher
    >
      <Breadcrumbs title={title} />
      <Header categories={data?.allWpCategory?.nodes} {...newsData} />
      <Content {...newsData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpCategory(filter: { language: { code: { eq: PL } } }) {
      nodes {
        name
        slug
        wpParent {
          node {
            slug
          }
        }
      }
    }
  }
`

export default News
